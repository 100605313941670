import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
// import Login1 from '../views/Login1.vue'


Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta:'兴城人居智慧管理平台'
  },
  // {
  //   path: '/',
  //   name: 'Login1',
  //   component: Login1,
  //   meta:'成都市兴城建实业发展有限责任公司'
  // },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/Index.vue'),
    meta:'首页',
    children: [
      { path: '/', component: () => import('../views/Index_map.vue'), meta: '首页'},
      { path: '/index/User_manage', component: () => import('../views/User_manage.vue'), meta: '用户管理'},
      { path: '/index/Organization_manage', component: () => import('../views/Organization_manage.vue'), meta: '组织管理'},
      { path: '/index/Permission_manage', component: () => import('../views/Permission_manage.vue'), meta: '权限管理'},
      { path: '/index/Menu_manage', component: () => import('../views/Menu_manage.vue'), meta: '菜单管理'},
      { path: '/index/Device', component: () => import('../views/Device.vue'), meta: '设备注册'},
      { path: '/index/Video_play', component: () => import('../views/Video_play.vue'), meta: '视频播放'},      
      { path: '/index/Video', component: () => import('../views/Video.vue'), meta: '视频监控'},
      { path: '/index/Access_log', component: () => import('../views/Access_log.vue'), meta: '访问日志'},
      { path: '/index/Operation_log', component: () => import('../views/Operation_log.vue'), meta: '操作日志'},
    ]
  },
]

const router = new VueRouter({
  routes
})





export default router
