<template>
  <div class="login">   
      <section>      
          <div class="left_img" alt="">
              <p>成都兴城人居地产投资集团</p>
              <p>项目远程监控系统</p>
              <img src="../assets/imgs/login-logo.png" alt="" class="img1">
              <img src="../assets/imgs/qrcode.jpg" alt="" class="img2" @mouseenter="imgModal=1" @mouseleave="imgModal=0">
              <img v-show="imgModal==1" src="../assets/imgs/qrcode.jpg" alt="" class="img3">
          </div>
          <form>
              <p>登录</p>
              <p>欢迎使用兴城人居智慧管理平台</p>
            <div class="int" v-show="loginState==0">
                <p>用户名</p>
               <el-input
                  placeholder="请输入您的账号"
                  prefix-icon="el-icon-user"
                  v-model="form.name" @blur="nameBlur">
                </el-input>
              </div> 
            <div class="int" v-show="loginState==0">
                <p>密码</p>
              <el-input
                  placeholder="请输入您的密码"                 
                  prefix-icon="el-icon-lock"
                  show-password
                  v-model="form.pwd">
                </el-input>
              </div>

              <div class="int" v-show="loginState==1">
                <p>手机号</p>
               <el-input
                  placeholder="请输入您的手机号"
                  prefix-icon="el-icon-user"
                  v-model="form.mobile" @blur="nameBlur">
                </el-input>
              </div> 
            <div class="int" v-if="loginState==1">
                <p>验证码
                    <button class="btn1" style="margin-left:120px;line-height:20px;height:22px" @click="sendCheckCode()" v-show="timerShow==0">获取</button>
                      <span class="gray666" style="margin-left:4px;font-size:12px" v-show="timerShow==1">请在<span style="color:#d62829">{{timer}}</span> s内填写</span>

                </p>
              <el-input
                  placeholder="请输入您的验证码"                 
                  prefix-icon="el-icon-lock"
                  v-model="form.code">
                </el-input>
              </div>
              <el-checkbox @change="checkChange" v-model="checked">记住用户名</el-checkbox>
            <p class='btn' @click="urlChanging('/index')">登 录</p>
            <div style='display:flex;margin-top: 28px;'>
              <p class="bottom" v-show="loginState==0" @click="loginState=1;form={}">使用手机验证码登陆</p>
            <p class="bottom" v-show="loginState==1" @click="loginState=0;form={}">使用账号密码登陆</p>
            <span @click="down" class="down">下载视频插件</span>
            </div>
            
          </form>
          <p class="bttitle">技术支持：智建通（成都）科技有限公司  服务热线：400-863-5881</p> 
      </section>
  </div>
</template>

<script>
import { login,getCaptcha,doLoginByPhone } from '@/api/apis.js'
import Utils from '@/utils/util.js'
export default {
     data() {
        return {
          form: {
            name: "",
            pwd: "",
            region:'监管部门',
          },
          checked:true,
          imgModal:0,
          loginState:0,
          timer:300,
          timerShow:0,
          t:0,
        };
     },
    methods: {
      urlChanging(url) {
        if(this.loginState==0){
            //验证账号密码
        if(!this.form.name.trim()){
            this.$message.error('请输入用户名');
            return
        }
        if(!this.form.pwd.trim()){
            this.$message.error('请输入密码');
            return
        }
        let data = {
                        username: this.form.name,
                        password: this.form.pwd,
                    }
        login(data).then(res => {         
          // 弹出登录成功消息
        if(res.code==200){
              //加解密
          var p = Utils.encrypt(res.data.tokenValue);
          //  var u = Utils.encrypt(res.userId);
          //  var a = Utils.encrypt(res.dataId);
          localStorage.setItem('p',p)  

            this.$router.push(url);                  
        }else{
          this.$message.error(res.data.msg)
        }      
      })
        }else if(this.loginState==1){
                  //验证账号密码
        if(!this.form.mobile.trim()){
            this.$message.error('请输入手机号');
            return
        }
        if(!this.form.code.trim()){
            this.$message.error('请输入验证码');
            return
        }
        let data = {
                        mobile: this.form.mobile,
                        code: this.form.code,
                    }
        doLoginByPhone(data).then(res => {         
          // 弹出登录成功消息
        if(res.code==200){
              //加解密
          var p = Utils.encrypt(res.data.tokenValue);
          //  var u = Utils.encrypt(res.userId);
          //  var a = Utils.encrypt(res.dataId);
          localStorage.setItem('p',p)  

            this.$router.push(url);                  
        }else{
          this.$message.error(res.data.msg)
        }      
      })
        }
        
      },
      down(){
        window.open(
   "https://xcrj2021.oss-cn-chengdu.aliyuncs.com/xcrj2021/video-play-exe/VideoWebPlugin.exe",
         "_blank" )
      },
        //发送验证码
    sendCheckCode() {
      let num = this.form.mobile
      if (!num||!num.trim()) {
        this.$message.error("请输入手机号码");
        return;
      }
      let flag = /^1[3-9]\d{9}$/.test(num);
        if (!flag) {
          this.$message.error("手机号格式不对,请重新输入");
          flag = false;
        }
      if (flag) {
        let d = {
          mobile: num,
        };
        getCaptcha(d).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "验证码发送成功,请注意查收",
              type: "success",
            });
            this.timerShow=1
            let _this=this
             this.t = setInterval(() => {
              --_this.timer;
              if(_this.timer<1){
                _this.timerShow=0
                _this.timer=120
                clearTimeout(_this.t);
              }
            }, 1000);
          }else{
            this.$message.error(res.data.msg)
          }
        });
      }
    },
      nameBlur(){
          if(this.checked&&this.loginState==0){
          localStorage.setItem('name',this.form.name)
        }
      },
      checkChange(){
        if(this.checked&&this.loginState==0){
          localStorage.setItem('name',this.form.name)
        }else if(!this.checked){
          localStorage.removeItem("name");
        }
      },
        register(url){
            this.$router.push(url);
        },
        enterKeyDown(e){
            if (e.keyCode == 13) {
        this.urlChanging('/index');
      }
        }
    },
    created(){
        let _this=this
        document.addEventListener('keydown', _this.enterKeyDown)
        if(localStorage.getItem("name")!='undefined'&&this.loginState==0){
          // this.checked=true
          this.form.name=localStorage.getItem("name")
        }
    },
    destroyed(){
        document.removeEventListener('keydown',this.enterKeyDown,false);
      clearTimeout(this.t);

  }
}
</script>

<style lang="less">
.login{
  width: 100%;
  height: 100%;
  background-image: url('../assets/imgs/back.png');
  background-size: 100% 100%;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  justify-content: center; /*子元素水平居中*/
            align-items: center; /*子元素垂直居中*/
            display:flex;
          section {
    display: flex;
    position: relative;
     .bttitle{
      position: absolute;
      bottom: -26%;
      width: 100%;
      text-align: center;
      color: #fff;
    }
    .left_img{
      width: 450px;
      height: 600px;
      background-color: rgba(220, 0, 10, 0.4);
      box-sizing: border-box;
      padding-top: 100px;
      padding-left: 48px;
      border-radius:  8px 0 0 8px ;
      position: relative;
      p{
          font-size: 26px;
          color: #fff;
          line-height: 50px;
          font-weight: 600;
      }
      &>p:nth-child(3){
          width: 82px;
          height: 16px;
          background-color: #fff;
          margin-top: 10px;
      }
      .img2{
        width: 60px;  
        height: 60px;
        margin-top: 290px;
        margin-left: 180px;
        position: relative;
        top: 18px;
      }
      .img3{
        position:absolute;
        top: 220px;
        left:77px;
      }
    }
    form{
  width: 450px;
  height:600px;
  padding-left: 50px;
  padding-top: 48px;
  background-color: #fff;
  border-radius: 0 8px 8px 0;
  box-sizing: border-box;
  &>p:nth-child(1){
          font-size: 28px;
          font-weight: 600;
      }
      &>p:nth-child(2){
          font-size: 12px;
          line-height: 50px;
          color: #484848;
          margin-bottom: 30px;
      }
  .title_img{
    margin-bottom: 18px;
  }
  .toptitle{
    font-size: 28px;
    color: #292c35;
    margin-bottom: 52px;
  }
   .el-input{
  width: 290px;
}
.el-input--prefix .el-input__inner {
    padding-left: 44px;
}
.el-checkbox{
  color:  #11192e;
}
.el-checkbox__inner::after{
  border-color:  #11192e;
}
.el-checkbox__input.is-checked .el-checkbox__inner{
  background-color: #fff;
  border-color: #11192e;
}
.el-checkbox__input.is-checked+.el-checkbox__label{
  color:  #11192e;
}
.el-checkbox__input .el-checkbox__inner:hover{
  border-color:  #11192e;
}
.el-checkbox__input.is-focus .el-checkbox__inner{
    border-color:  #11192e;
  }
.el-checkbox__input .el-checkbox__inner{
  border-color:  #11192e;
}
.int {
  margin-bottom:20px;
  p{
      line-height: 36px;
      font-size: 14px;
      color: #222;
  }
}
.ashen {
  color: #47494e;
  margin-bottom: 30px;
  text-align: center;
  font-size: 28px;
}
input {
  color: #11192e;
}
input::-webkit-input-placeholder {
  color: #9b9b9b;
  font-size: 14px;
}
.el-input__prefix{
  left:14px
}
i {
  color: #9b9b9b;
  font-size: 18px;
}
.btn{
  background-color: #c8000a;
  width: 290px;
  line-height: 40px;
  margin-top: 30px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
.btn:hover{
  filter: brightness(1.1);
}
.bottom{
  font-size: 14px;
  cursor: pointer;
  color: #fe793e;
  
}
.down{
  font-size: 12px;
  cursor: pointer;
  color: #0090ff;
  margin-left: 20px;
}

}
}
}





</style>
